import { getDetailedMessage } from '@zola-helpers/client/dist/es/redux/auth/authActions';
import { identifyUserSailthru } from '@zola-helpers/client/dist/es/redux/user/userActions';
import * as toastsActions from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';
import { getCookie, setCookie, setAllStorage } from '@zola-helpers/client/dist/es/util/storage';
import { trackOnboardingCompleted } from '@zola-helpers/client/dist/es/tracking/onboard/onboardEvents';
import { trackUserCreated } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import {
  BUSINESS_UNIT,
  COMPONENT,
} from '@zola-helpers/client/dist/es/tracking/onboard/onboardTrackingConstants';
import { createDefaultRegistryUser } from '@zola-helpers/client/dist/es/api/onboard';
import { setGTMLoggedIn } from '@zola-helpers/client/dist/es/tracking/googleTagManager';
import { getDateUtc } from '@zola-helpers/client/dist/es/util/dateUtils';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import Logger from '../util/logger';
import * as ActionType from './types/OnboardActionTypes';
import { getUserContext } from './UserActions';
// Selectors
import * as userSelectors from '../selectors/userSelectors';

function requestQuestions() {
  return {
    type: ActionType.REQUEST_QUESTIONS,
  };
}

function receiveQuestions(json) {
  return {
    type: ActionType.RECEIVE_QUESTIONS,
    payload: json,
  };
}

function requestSignup() {
  return {
    type: ActionType.REQUEST_SIGNUP,
  };
}

function onboardingCompleted(json) {
  return {
    type: ActionType.ONBOARDING_COMPLETED,
    payload: json,
  };
}

export function updateQuestionNumber(questionNumber) {
  return {
    type: ActionType.UPDATE_QUESTION_NUMBER,
    payload: questionNumber,
  };
}

export function updateGiftsAddedCount(questionNumber) {
  return {
    type: ActionType.UPDATE_GIFTS_ADDED_COUNT,
    payload: questionNumber,
  };
}

export function updateWeddingDate(date) {
  return {
    type: ActionType.UPDATE_WEDDING_DATE,
    payload: {
      date,
    },
  };
}

export function setOnboardAddToRegistryProductData(productData) {
  return {
    type: ActionType.SET_ADD_TO_REGISTRY_PRODUCT_DATA,
    productData,
  };
}

export function setOnboardAddToRegistryEventData(eventData) {
  return {
    type: ActionType.SET_ADD_TO_REGISTRY_EVENT_DATA,
    eventData,
  };
}

export function setOnboardRedirect(path, isRegistry = true) {
  return {
    type: ActionType.SET_ONBOARD_REDIRECT,
    path,
    isRegistry,
  };
}

export function clearOnboardProductData() {
  return {
    type: ActionType.CLEAR_ONBOARD_ADD_REGISTRY_DATA,
  };
}

export function setSubmitDelayActive(bool) {
  return {
    type: ActionType.UPDATE_SUBMIT_DELAY_ACTIVE,
    payload: bool,
  };
}

export function fetchQuestions() {
  return (dispatch) => {
    dispatch(requestQuestions());
    return ApiService.get('/web-registry-api/v1/onboard/get')
      .then((json) => dispatch(receiveQuestions(json)))
      .catch((err) => {
        Logger.error(err.message, err);
        dispatch(
          toastsActions.negative({ headline: 'Error in getting questions for onboarding.' })
        );
      });
  };
}

const handleOnboardSubmitErrors = (err, dispatch) => {
  const detailedMessage = getDetailedMessage(err.response);
  Logger.warn(detailedMessage, err);
  dispatch(toastsActions.negative({ headline: detailedMessage }));
};

export function submitOnboarding(data) {
  return (dispatch, getState) => {
    const { userContext } = getState().user; // Do this up here so you do not have a modify userContext after dispatching any account creation events.
    const isExistingUser =
      userContext.has_registry ||
      userContext.has_wedding_account ||
      userContext.has_invitation_account ||
      userContext.has_marketplace_account;
    const isGuest = userContext && userContext.is_guest;
    dispatch(requestSignup());
    return ApiService.post('/web-registry-api/v1/onboard/submit', data)
      .then((json) => {
        setAllStorage('zolaUserCreatedAt', new Date().toISOString());
        setGTMLoggedIn();
        return dispatch(getUserContext()).then(() => {
          const state = getState();
          const { user } = state;
          const latestUserContext = user.userContext || {};

          if (isGuest) {
            // track user created
            let cookie = getCookie('pkey');
            if (cookie === '') {
              cookie = null;
            }
            trackUserCreated(latestUserContext.email, cookie);
          }

          identifyUserSailthru(latestUserContext.email, isGuest ? data.user : undefined);

          const weddingDate = userSelectors.getUserWeddingDate(state);
          trackOnboardingCompleted(BUSINESS_UNIT.WEDDING_REGISTRY, COMPONENT.REGISTRY, {
            isExistingUser,
            weddingDate,
          });
          // set a flag for checklist to know that the user just signed up
          setCookie('checklistOnboard', true, 1);

          dispatch(onboardingCompleted(json));

          return json;
        });
      })
      .catch((err) => handleOnboardSubmitErrors(err, dispatch));
  };
}

export function submitDefaultOnboarding() {
  return (dispatch) => {
    dispatch(requestSignup());
    const startedAt = getDateUtc().toISOString();
    return createDefaultRegistryUser(startedAt)
      .then((json) => {
        dispatch(getUserContext());
        dispatch(onboardingCompleted(json));

        return json;
      })
      .catch((err) => handleOnboardSubmitErrors(err, dispatch));
  };
}
