import * as toastsActions from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';
import ApiService from '../util/apiService';
import Logger from '../util/logger';
import * as ActionType from './types/RegistryCollectionActionTypes';

function requestAddToRegistry() {
  return {
    type: ActionType.REQUEST_ADD_TO_REGISTRY,
  };
}

function receiveAddToRegistry(json) {
  return {
    type: ActionType.RECEIVE_ADD_TO_REGISTRY,
    payload: json,
  };
}

function requestRemoveFromRegistry() {
  return {
    type: ActionType.REQUEST_REMOVE_FROM_REGISTRY,
  };
}

function receiveRemoveFromRegistry(json) {
  return {
    type: ActionType.RECEIVE_REMOVE_FROM_REGISTRY,
    payload: json,
  };
}

function requestRegistryCollection() {
  return {
    type: ActionType.REQUEST_REGISTRY_COLLECTION,
  };
}

function requestSearch() {
  return {
    type: ActionType.REQUEST_SEARCH,
  };
}

function receiveSearch(json) {
  return {
    type: ActionType.RECEIVE_SEARCH,
    payload: json,
  };
}

function selectCollection(collectionId) {
  return {
    type: ActionType.SELECT_COLLECTION,
    payload: collectionId,
  };
}

export function receiveRegistryCollection(json) {
  return {
    type: ActionType.RECEIVE_REGISTRY_COLLECTION,
    payload: json,
  };
}

export function toggleFacetValue(facetOption) {
  return {
    type: ActionType.TOGGLE_FACET_VALUE,
    payload: facetOption,
  };
}

export function resetAllFacetValue() {
  return {
    type: ActionType.RESET_ALL_FACET_VALUE,
  };
}

export function resetFacetValue(facetKey) {
  return {
    type: ActionType.RESET_FACET_VALUE,
    payload: facetKey,
  };
}

export function getRegistryProducts({
  groupedByCollection = false,
  flattenedView = false,
  registryKey,
  filters = {},
  collectionObjectId = null,
  sort = null,
}) {
  return (dispatch) => {
    dispatch(requestSearch());
    dispatch(selectCollection(collectionObjectId));
    const request = {
      registry_key: registryKey,
      filters,
      flattened_view: flattenedView,
      collection_object_id: collectionObjectId,
      grouped_by_collection: groupedByCollection,
    };

    if (sort && sort !== 'FEATURED') {
      request.sort = sort;
    }

    return ApiService.post('/web-registry-api/v1/registryCollection/search', request)
      .then((json) => dispatch(receiveSearch(json)))
      .catch((err) => {
        Logger.error(err.message, err);
        dispatch(toastsActions.negative({ headline: "Sorry we couldn't fetch your registry." }));
      });
  };
}

export function getRegistryProductsByRegistryId({ registryObjectId }) {
  return (dispatch) => {
    dispatch(requestSearch());
    dispatch(selectCollection(registryObjectId));

    const request = {
      registry_object_id: registryObjectId,
      filters: {
        AVAILABILITY: ['AVAILABLE'],
        STORE: ['ZOLA'],
      },
    };
    return ApiService.post('/web-registry-api/v1/registryCollection/search', request)
      .then((json) => dispatch(receiveSearch(json)))
      .catch((err) => {
        Logger.error(err.message, err);
        dispatch(toastsActions.negative({ headline: "Sorry we couldn't fetch your registry." }));
      });
  };
}

export function addToRegistry(data, productShippingZones) {
  return (dispatch) =>
    ApiService.get('/web-registry-api/v1/registry/user-info').then((resp) => {
      const fetchedShippingAddressInitials =
        resp && resp.address_view && resp.address_view.state_province_region
          ? resp.address_view.state_province_region
          : 'no address';
      const doesUserLiveInExclusionZone = ['HI', 'AK'].indexOf(fetchedShippingAddressInitials) > -1;

      const isProductInExclusionZone =
        productShippingZones &&
        productShippingZones.length > 0 &&
        productShippingZones.indexOf('US_ALASKA_AND_HAWAII') < 0;
      if (isProductInExclusionZone && doesUserLiveInExclusionZone) {
        const message = 'Product cannot be shipped to Hawaii or Alaska';
        return dispatch(toastsActions.negative({ headline: message }));
      }

      dispatch(requestAddToRegistry());
      return ApiService.post('/web-registry-api/v1/registryCollection/add', data)
        .then((json) => dispatch(receiveAddToRegistry(json)))
        .catch((err) => {
          dispatch(toastsActions.negative({ headline: err.message }));
        });
    });
}

export function addCashFundToRegistry(data) {
  return (dispatch) => {
    dispatch(requestAddToRegistry());
    return ApiService.post('/web-registry-api/v1/registryCollection/add-cash', data)
      .then((json) => dispatch(receiveAddToRegistry(json)))
      .catch((err) => {
        dispatch(toastsActions.negative({ headline: err.message }));
      });
  };
}

export function addExternalItemToRegistry(data) {
  return (dispatch) => {
    dispatch(requestAddToRegistry());
    return ApiService.post('/web-registry-api/v1/registryCollection/external-product', data).then(
      (json) => dispatch(receiveAddToRegistry(json))
    );
  };
}

export function removeFromRegistry(data) {
  const { collectionItemId, userId } = data;
  return (dispatch) => {
    dispatch(requestRemoveFromRegistry());
    return ApiService.delete(
      `/web-registry-api/v1/registryCollection/delete/${collectionItemId}?userId=${userId}`
    )
      .then((json) => dispatch(receiveRemoveFromRegistry(json)))
      .catch((err) => {
        Logger.error(err.message, err);
        dispatch(
          toastsActions.negative({
            headline: "Sorry we couldn't remove this product from your registry.",
          })
        );
      });
  };
}

export function getCollectionProducts(collectionKey) {
  const PRODUCTS_IN_COLLECTION = 14;
  let key = collectionKey;
  if (!key) {
    key = 'zola-registry-essentials';
  }
  return (dispatch) => {
    dispatch(requestRegistryCollection());
    return ApiService.get(
      `/api/v0/gift-collection/key/${key}/sorted?limit=${PRODUCTS_IN_COLLECTION}`
    ).catch((err) => {
      Logger.error(err.message, err);
      dispatch(toastsActions.negative({ headline: "Sorry we couldn't fetch any products." }));
    });
  };
}

export function updateProduct(skuId, itemId) {
  return (dispatch) => {
    dispatch({
      type: ActionType.UPDATE_PRODUCT,
      skuId,
      itemId,
    });
  };
}
