import * as toastsActions from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import LogService from '@zola-helpers/client/dist/es/util/logService';
import * as ActionType from './types/CartActionTypes';
import { setShippingRequestAttributes } from '../util/shippingHelper';

export const logCheckoutError = (errorMessage, payload = {}, cartId = undefined) => {
  const finalPayload = { ...payload, cartId: payload.cartId || cartId, checkoutRedesign: true };
  return LogService.log(errorMessage, finalPayload);
};

function cacheBust() {
  return new Date().toString();
}

function requestSize() {
  return {
    type: ActionType.REQUEST_SIZE,
  };
}

function receiveSize(response) {
  return {
    type: ActionType.RECEIVE_SIZE,
    payload: response.data && response.data.items && response.data.items.length, // temporary
  };
}

function requestCart() {
  return {
    type: ActionType.REQUEST_CART,
  };
}

function receiveCart(response) {
  return {
    type: ActionType.RECEIVE_CART,
    payload: response.data,
  };
}
function requestUpdatedCart() {
  return {
    type: ActionType.REQUEST_UPDATED_CART,
  };
}
function receiveUpdatedCart(response = {}) {
  return {
    type: ActionType.RECEIVE_UPDATED_CART,
    payload: response,
  };
}
function requestInitialCartDetails() {
  return {
    type: ActionType.REQUEST_INITIAL_CART_DETAILS,
  };
}

function receiveInitialCartDetails(response) {
  return {
    type: ActionType.RECEIVE_INITIAL_CART_DETAILS,
    payload: response.data,
  };
}

function requestApplyCredits() {
  return {
    type: ActionType.REQUEST_APPLY_CREDITS,
  };
}

function requestCreditCard() {
  return {
    type: ActionType.REQUEST_CREDIT_CARD,
  };
}

function requestShippingValidation() {
  return {
    type: ActionType.REQUEST_SHIPPING_VALIDATION,
  };
}

function receiveShippingValidation(response, allowBypass) {
  return {
    type: ActionType.RECEIVE_SHIPPING_VALIDATION,
    payload: {
      ...response,
      allowBypass,
    },
  };
}

function receiveCreditCard(response) {
  return {
    type: ActionType.RECEIVE_CREDIT_CARD,
    payload: response.data,
  };
}

function requestRemoveCredits() {
  return {
    type: ActionType.REQUEST_REMOVE_CREDITS,
  };
}

function requestApplyPromo() {
  return {
    type: ActionType.REQUEST_APPLY_PROMO,
  };
}

function requestRemovePromo() {
  return {
    type: ActionType.REQUEST_REMOVE_PROMO,
  };
}

function requestRemoveCreditCard() {
  return {
    type: ActionType.REQUEST_REMOVE_CREDIT_CARD,
  };
}

function receiveRemoveCreditCard(response) {
  return {
    type: ActionType.RECEIVE_REMOVE_CREDIT_CARD,
    payload: response.data,
  };
}

function requestSubmitCheckout() {
  return {
    type: ActionType.REQUEST_SUBMIT_CHECKOUT,
  };
}

function receiveSubmitCheckout(response) {
  return {
    type: ActionType.RECEIVE_SUBMIT_CHECKOUT,
    payload: response.data,
  };
}

function requestAddItemToCart() {
  return {
    type: ActionType.ADD_ITEM_TO_CART,
  };
}

function receiveAddItemToCart(json) {
  return {
    type: ActionType.ADDED_ITEM_TO_CART,
    payload: json,
  };
}

function requestBraintreeToken() {
  return {
    type: ActionType.REQUEST_BRAINTREE_TOKEN,
  };
}

function receiveBraintreeToken(json) {
  return {
    type: ActionType.RECEIVE_BRAINTREE_TOKEN,
    payload: json,
  };
}

function addItem2() {
  return {
    type: ActionType.ADD_ITEM,
  };
}

function addedItem(json) {
  return {
    type: ActionType.ADDED_ITEM,
    payload: json,
  };
}

function receiveDeletedItems(items) {
  return {
    type: ActionType.RECEIVE_DELETED_ITEMS,
    payload: items,
  };
}

export function unloadCart() {
  return {
    type: ActionType.UNLOAD_CART,
  };
}

export function resetCart() {
  return {
    type: ActionType.RESET_CART,
  };
}

export function getCartSize() {
  return (dispatch) => {
    dispatch(requestSize());
    return ApiService.get(`/api/v0/cart?cache=${cacheBust()}`).then((json) =>
      dispatch(receiveSize(json))
    );
  };
}

export function addItemToCart(body) {
  return (dispatch) => {
    dispatch(requestAddItemToCart());
    return ApiService.post('/web-registry-api/v1/cart/item', body)
      .then((json) => dispatch(receiveAddItemToCart(json)))
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };
}

export function addItem(addItemRequest) {
  return (dispatch) => {
    dispatch(addItem2());
    return ApiService.post('/api/v0/cart/item', addItemRequest)
      .then((json) => {
        // TODO: This may not work with ApiService so may need to be in the catch
        if (json && json.errorMessage) {
          const error = new Error(json.errorMessage);
          error.response = json;
          dispatch(toastsActions.negative({ headline: json.errorMessage }));
          throw error;
        } else {
          dispatch(addedItem(json));
          return json;
        }
      })
      .catch((error) => {
        dispatch(toastsActions.negative({ headline: error.response.errorMessage }));
        throw error.response;
      });
  };
}

export function removeItem(id, isExpedited, isRushed, zipCode, registryId) {
  return (dispatch) => {
    dispatch(requestUpdatedCart());

    const baseReqAttributes = setShippingRequestAttributes(isExpedited, isRushed);

    return ApiService.post(
      `/web-registry-api/v1/checkout/cart/item/id/${id}`,
      !registryId ? { ...baseReqAttributes, zipCode } : { ...baseReqAttributes, registryId }
    ).then((json) => dispatch(receiveUpdatedCart(json.data)));
  };
}

//! TODO: review function params
export function removeSuiteItems(itemIds, isExpedited, isRushed, zipCode, registryId) {
  return (dispatch) => {
    dispatch(requestUpdatedCart());

    const body = {
      itemIds,
      ...setShippingRequestAttributes(isExpedited, isRushed),
      zipCode: !registryId ? zipCode : undefined,
      registryId: registryId || undefined,
    };

    return ApiService.post(`/web-registry-api/v1/checkout/cart/items`, body).then((json) => {
      dispatch(toastsActions.negative({ headline: 'Items removed from cart' }));
      return dispatch(receiveUpdatedCart(json.data));
    });
  };
}

export function removeItems(itemIds) {
  return (dispatch) => {
    dispatch(requestCart());
    return ApiService.post(`/web-registry-api/v1/cart/items`, { itemIds }).then((json) =>
      dispatch(receiveCart(json))
    );
  };
}

/**
 * Updates cart totals based on zipcode and delivery method
 * @param {Object} body - request body that has following shape:
    "registryId" -> optional(nonEmptyText),
    "zipCode" -> optional(nonEmptyText),
    "isExpedited" -> boolean
    "isRushed" -> boolean
 */
export function updateTotals(body) {
  return (dispatch) => {
    return ApiService.put(`/web-registry-api/v1/checkout/totals`, body).then((json) =>
      dispatch(receiveUpdatedCart(json.data))
    );
  };
}

export function getCart() {
  return (dispatch) => {
    dispatch(requestCart());

    return ApiService.get('/web-registry-api/v1/cart').then((json) => {
      if (json.data.items && json.data.items.length > 0) {
        // if cart has out of stock items, delete them and return updated result
        const OUT_OF_STOCK = 'out of stock';
        const itemsToDelete = json.data.items.filter(
          (item) =>
            item.availability && item.availability.stockStatus.toLowerCase() === OUT_OF_STOCK
        );
        if (itemsToDelete.length > 0) {
          dispatch(receiveDeletedItems(itemsToDelete));
          const ids = itemsToDelete.map((item) => item.id);
          return dispatch(removeItems(ids));
        }
      }
      return dispatch(receiveCart(json));
    });
  };
}

export function getInitialCartDetails() {
  return (dispatch) => {
    dispatch(requestInitialCartDetails());

    return ApiService.get('/web-registry-api/v1/checkout').then((json) =>
      dispatch(receiveInitialCartDetails(json))
    );
  };
}

export function checkShippingAddress(body, allowBypass) {
  return (dispatch) => {
    dispatch(requestShippingValidation());

    return ApiService.post('/web-registry-api/v1/checkout/validate-address', body).then((json) => {
      if (json.data && json.data.userMessage) {
        dispatch(receiveShippingValidation(json, allowBypass));
        throw Error(json.data.userMessage);
      }
      return dispatch(receiveShippingValidation(json, allowBypass));
    });
  };
}

export function updateItemQuantity(id, quantity, isExpedited, isRushed, zipCode, registryId) {
  return (dispatch) => {
    dispatch(requestUpdatedCart());

    const baseReqAttributes = {
      ...setShippingRequestAttributes(isExpedited, isRushed),
      quantity,
    };

    return ApiService.put(
      `/web-registry-api/v1/checkout/cart/item/id/${id}`,
      !registryId ? { ...baseReqAttributes, zipCode } : { ...baseReqAttributes, registryId }
    )
      .then((json) => {
        if (json && json.data.errorMessage) {
          dispatch(toastsActions.negative({ headline: json.data.errorMessage }));
        }
        return dispatch(receiveUpdatedCart(json.data.cart));
      })
      .catch((error) => {
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error;
      });
  };
}

export function getDefaultCreditCard() {
  return (dispatch) => {
    dispatch(requestCreditCard());
    return ApiService.get('/api/v0/cart/credit-cards/get-credit-cards').then((json) =>
      dispatch(receiveCreditCard(json))
    );
  };
}

export function removeCreditCard(cardId) {
  return (dispatch) => {
    dispatch(requestRemoveCreditCard());
    return ApiService.post('/api/v0/cart/credit-cards/remove-credit-card', cardId)
      .then((json) => dispatch(receiveRemoveCreditCard(json)))
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };
}

export function submitCheckout(body) {
  return (dispatch, getState) => {
    // return (dispatch) => {
    dispatch(requestSubmitCheckout());

    return ApiService.post('/web-registry-api/v1/checkout', body)
      .then((json) => dispatch(receiveSubmitCheckout(json)))
      .catch((error) => {
        const cartId =
          getState().cart && getState().cart.checkoutData
            ? getState().cart.checkoutData.cartId
            : undefined;
        const errorPayload = {
          request: body,
          requestResponse: error.response,
        };
        const errorId = logCheckoutError(error.message, errorPayload, cartId);
        dispatch(toastsActions.negative({ headline: `${error.message} (Support ID: ${errorId})` }));
        throw error;
      });
  };
}

export function applyCredits(isExpedited, isRushed, zipCode, registryId) {
  return (dispatch) => {
    dispatch(requestApplyCredits());

    const baseReqAttributes = setShippingRequestAttributes(isExpedited, isRushed);

    return ApiService.post(
      '/web-registry-api/v1/checkout/apply-credit',
      !registryId ? { ...baseReqAttributes, zipCode } : { ...baseReqAttributes, registryId }
    )
      .then((json) => dispatch(receiveUpdatedCart(json.data)))
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };
}

export function removeCredits(isExpedited, isRushed, zipCode, registryId) {
  return (dispatch) => {
    dispatch(requestRemoveCredits());

    const baseReqAttributes = setShippingRequestAttributes(isExpedited, isRushed);

    return ApiService.post(
      '/web-registry-api/v1/checkout/remove-credit',
      !registryId ? { ...baseReqAttributes, zipCode } : { ...baseReqAttributes, registryId }
    )
      .then((json) => dispatch(receiveUpdatedCart(json.data)))
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };
}

export function applyPromo(isExpedited, isRushed, promoCode, zipCode, registryId) {
  return (dispatch) => {
    dispatch(requestApplyPromo());

    const baseReqAttributes = {
      ...setShippingRequestAttributes(isExpedited, isRushed),
      promoCode,
    };

    return ApiService.post(
      '/web-registry-api/v1/checkout/apply-promo',
      !registryId ? { ...baseReqAttributes, zipCode } : { ...baseReqAttributes, registryId }
    )
      .then((json) => {
        if (json?.data?.errorTrigger) {
          throw json.data.errorTrigger;
        } else {
          return dispatch(receiveUpdatedCart(json.data));
        }
      })
      .catch((response) => {
        throw response?.response?.error?.trigger || response;
      });
  };
}

export function removePromo(isExpedited, isRushed, promoCode, zipCode, registryId) {
  return (dispatch) => {
    dispatch(requestRemovePromo());

    const baseReqAttributes = {
      ...setShippingRequestAttributes(isExpedited, isRushed),
      promoCode,
    };

    return ApiService.post(
      '/web-registry-api/v1/checkout/remove-promo',
      !registryId ? { ...baseReqAttributes, zipCode } : { ...baseReqAttributes, registryId }
    )
      .then((json) => dispatch(receiveUpdatedCart(json.data)))
      .catch((response) => {
        const error =
          response.response && response.response.error && response.response.error.message;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };
}

export function getBraintreeToken() {
  return (dispatch) => {
    dispatch(requestBraintreeToken());

    return ApiService.get('/web-registry-api/v1/checkout/braintree/token').then((json) =>
      dispatch(receiveBraintreeToken(json.data.braintreeToken))
    );
  };
}
