import experimentFlags, { CLP_LP_LINKS_TO_SRP } from '@/util/experimentFlags';
import { UserContext } from '@zola-helpers/client/dist/es/@types';

/**
 * Trigger marketplace-clp-lp-links-to-srp experiment viewed, since we only want it triggered when the link is clicked
 * @param userContext
 */
export const triggerSrpLinkExperimentViewed = (userContext: UserContext | null | undefined) => {
  if (typeof window !== 'undefined' && userContext && !userContext.is_guest) {
    experimentFlags.isUnderTest(CLP_LP_LINKS_TO_SRP, userContext);
  }
};
